import { initScrollbar } from '../modules/scrollbar';

const initBlock = ( $block ) => {
	const $sectionSlider = $block.find( '.js-section-slider' );

	/**
	 * Initialize scrollbar.
	 */
	initScrollbar( $block.find( '.js-scrollbar' ) );

	/**
	 * Initialize section sliders.
	 */
	app.loadSwiper( ( Swiper, modules, defaultOptions ) => {
		$sectionSlider.each( ( _, slider ) => {
			const swiperOptions = {
				modules: [ modules.Navigation ],
				loop: true,
				loopAddBlankSlides: false,
				slidesPerGroup: 3,
				slidesPerView: 3,
				speed: defaultOptions.speed,
				navigation: {
					nextEl: '.js-btn-next',
					prevEl: '.js-btn-prev',
				},
				breakpoints: {
					320: {
						spaceBetween: 10,
						slidesPerGroup: 1,
						slidesPerView: 1.5,
					},
					768: {
						spaceBetween: 16,
					},
					1440: {
						spaceBetween: 20,
					}
				},
			};

			let swiper = new Swiper( slider, swiperOptions );

			$( window ).on( 'resize', () => {
				swiper.destroy( true, true );
				swiper = new Swiper( slider, swiperOptions );
			} );
		} );
	} );
};

app.loadBlock( initBlock, 'about-us', '.js-section-about' );
